import { Suspense, lazy, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import LoadingState from 'components/LoadingState';
import Page from 'components/Page';
import { usePlannerParams } from './hooks/usePlannerParams';
import { useGetPatientList } from './requests/useGetPatientList';

const PlannerListPageLazy = lazy(
  () => import(/* webpackChunkName: "PlannerListPage" */ './PlannerListPage'),
);

const PlannerListPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const parsedParams = usePlannerParams();
  const { isLoading, isError, data } = useGetPatientList(parsedParams);

  const hasNoSearchParams = searchParams.size === 0;

  useEffect(() => {
    if (hasNoSearchParams) {
      setSearchParams({
        targetAreaManagement: 'practice',
        page: '0',
        targetAreaState: 'incomplete',
      });
    }
  });

  return (
    <Suspense fallback={<LoadingState />}>
      <Page>
        <PlannerListPageLazy
          isLoading={isLoading || hasNoSearchParams}
          isError={isError}
          data={data}
        />
      </Page>
    </Suspense>
  );
};

export default PlannerListPage;
