/* eslint-disable unicorn/no-null */
import { z } from 'zod';
import camelcaseKeys from 'camelcase-keys';

export const ZPartnerDashboardName = z.object({
  first: z.string(),
  last: z.string(),
});

export type IPartnerDashboardName = z.infer<typeof ZPartnerDashboardName>;

export const ZDashboardPartner = z
  .object({
    _id: z.string(),
    name: ZPartnerDashboardName,
    practice: z.object({
      name: z.string(),
      lastSynced: z.string().optional(),
    }),
    featureFlags: z
      .object({
        'partner-planner': z.boolean(),
      })
      .optional(),
  })
  .transform((patient) => camelcaseKeys(patient));

export type IDashboardPartner = z.infer<typeof ZDashboardPartner>;

export const ZPartnerPatient = z.object({
  _id: z.string(),
  name: ZPartnerDashboardName,
  nhs_number: z.string(),
  date_of_birth: z
    .union([z.date(), z.null(), z.undefined(), z.string()])
    .transform((value) => (value ? new Date(value) : null)),
  dateOfLastSms: z
    .union([z.date(), z.null(), z.undefined(), z.string()])
    .transform((value) => (value ? new Date(value) : null)),
  lastSmsContent: z
    .string()
    .nullable()
    .optional()
    .transform((value) => value || null),
  conditions: z.string().array(),
  ndhTargetAreaState: z.enum(['Complete', 'Incomplete']).optional(),
});

export type IPartnerPatient = z.infer<typeof ZPartnerPatient>;

export const ZPartnerPatientDetails = ZPartnerPatient.transform((patient) =>
  camelcaseKeys(patient),
);

export type IPartnerPatientDetails = z.infer<typeof ZPartnerPatientDetails>;

export const ZPartnerReferredPatient = z
  .object({
    _id: z.string(),
    name: ZPartnerDashboardName,
    nhs_number: z.string(),
    date_of_birth: z.string().optional(),
  })
  .transform((patient) => camelcaseKeys(patient));

export type IPartnerReferredPatient = z.infer<typeof ZPartnerReferredPatient>;
