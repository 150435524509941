import { useAuth0 } from '@auth0/auth0-react';
import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT!;
let axiosInstance: AxiosInstance | null = null;

export const useAuthenticatedAxios = () => {
  const { getAccessTokenSilently } = useAuth0();

  if (axiosInstance !== null) return axiosInstance;

  axiosInstance = axios.create({
    baseURL: API_ENDPOINT,
  });

  axiosInstance.interceptors.request.use(async (config) => {
    const accessToken = await getAccessTokenSilently();
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${accessToken}`,
      } as AxiosRequestHeaders,
    };
  });

  return axiosInstance;
};
