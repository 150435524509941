import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';

import { palette } from './palette';
import { typography } from './typography';
import { muiDataGridOverrides } from './overrides/muiDataGrid';

export const theme = createTheme({
  typography,
  palette,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: '600',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        sizeLarge: { padding: '10px 14px', fontSize: '16px' },
        sizeMedium: { padding: '6px 6px', fontSize: '16px' },
        sizeSmall: {
          padding: '2px 6px',
          fontSize: '14px !important',
          height: '32px',
        },
        outlined: {
          backgroundColor: palette.colors.white,
          borderColor: palette.colors.blue,
          borderWidth: '2px',
          '&:hover': {
            color: palette.colors.blueDark,
            borderColor: palette.colors.blueDark,
            borderWidth: '2px',
          },
        },
        text: {
          '&:hover': {
            color: palette.colors.blueDark,
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiDataGrid: muiDataGridOverrides,
    MuiFormLabel: {
      styleOverrides: {
        root: {
          ...typography.body2,
          color: palette.colors.greyDark,
          fontSize: '14px',
          marginBottom: '8px',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: palette.colors.blue,
          textDecorationColor: palette.colors.blue,
          ':hover': {
            color: palette.colors.blueDark,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& input': {
            padding: '8px 12px',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: '14px',
          borderColor: palette.colors.grey,
          borderRadius: '4px',
        },
        sizeSmall: {
          padding: '8px 0px',
        },
        outlined: {
          backgroundColor: palette.colors.greyLightest,
        },
        outlinedPrimary: {
          borderColor: palette.colors.blue,
          backgroundColor: palette.colors.blueLight,
          color: palette.colors.black,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: palette.colors.grey,
          borderBottomWidth: '0.5px',
        },
        vertical: {
          borderRightWidth: '0.5px',
        },
      },
    },
  },
});
