import { z } from 'zod';

const interactionTypesWithDiscriminatedIInteraction = [
  'patient_details_updated',
  'patient_alert_created',
] as const;

export const consultationTypes = [
  'routine_review',
  'urgent_review',
  'intro_call',
  'reengagement_call',
  'opt_out_call',
  'admin_note',
  'compound_consultation',
  'other',
] as const;
export const ZConsultationType = z.enum(consultationTypes);
export type ConsultationType = z.infer<typeof ZConsultationType>;

export const careTeamManualInteractionTypes = [
  'clinician_email_clinical_submission',
  'clinician_phone_clinical_submission',
  'clinician_sms_clinical_submission',
  'clinician_other_clinical_submission',
  'sms_outgoing',
  'email_outgoing',
] as const;

const patientManualInteractionTypes = [
  'webform_1_clinical_submission',
  'webform_2_clinical_submission',
] as const;

export const otherInteractionTypes = [
  ...careTeamManualInteractionTypes,
  ...patientManualInteractionTypes,
  'appointment' as const,
  'intro_message_ehr' as const,
  'type_sms_fetch_succesful' as const,
  'webform_5_clinical_submission' as const,
  'webform_clinical_submission' as const,
  'clinician_consent_service' as const,
  'patient_questionnaire_completed' as const,
  // Generic type for any form filled on dashboard (lifestyle, diabetes info etc.)
  'clinician_questionnaire_completed' as const,
] as const;
export const ZOtherInteractionType = z.enum(otherInteractionTypes);
export type OtherInteractionType = z.infer<typeof ZOtherInteractionType>;

export const allInteractionTypes = [
  ...interactionTypesWithDiscriminatedIInteraction,
  ...otherInteractionTypes,
  ...consultationTypes,
] as const;

export const ZInteractionType = z.enum(allInteractionTypes);
export type EInteractionType = z.infer<typeof ZInteractionType>;

export const consultationTypeSet: ReadonlySet<EInteractionType> = new Set(
  consultationTypes,
);

export const isConsultation = (interaction_type: EInteractionType) =>
  consultationTypeSet.has(interaction_type);

export const ZIInteractionType = z.object({
  _id: ZInteractionType,
  name: z.string(),
});
export type IInteractionType = z.infer<typeof ZIInteractionType>;
