import { z } from 'zod';
import { ZDateString } from './date';

export const ZAppointment = z.object({
  _id: z.string(),
  patient: z.object({
    _id: z.string(),
    name: z.object({
      first: z.string(),
      last: z.string(),
    }),
    email: z.string(),
    phone: z.string(),
  }),
  datetime: ZDateString,
  datetime_created: ZDateString,
  formatted_datetime: z.string(),
  duration: z.number(),
  suverian: z.object({
    name: z.string(),
    calendar_id: z.string(),
  }),
  type: z.object({
    name: z.string(),
    type_id: z.string(),
  }),
  cancelled: z.boolean(),
  rescheduling_link: z.string(),
});
export type IAppointment = z.infer<typeof ZAppointment>;

export const ZCalendar = z.object({
  _id: z.string(),
  email: z.string(),
  suverian: z.object({
    name: z.object({
      first: z.string(),
      last: z.string(),
    }),
  }),
});
export type ICalendar = z.infer<typeof ZCalendar>;

export const ZAppointmentSlot = z.object({
  calendar: z.string(),
  appointment_type: z.string(),
  datetime: z.date(),
});
export type IAppointmentSlot = z.infer<typeof ZAppointmentSlot>;

export const ZAcuityAppointmentSlot = z.object({
  calendarID: z.string(),
  appointmentTypeID: z.string(),
  datetime: z.date(),
});
export type IAcuityAppointmentSlot = z.infer<typeof ZAcuityAppointmentSlot>;

const ZAcuityAppointmentSlotValidated = z
  .object({
    datetime: z.string(),
    appointmentTypeID: z.number(),
    calendarID: z.number(),
    valid: z.boolean(),
  })
  .transform((slot) => ({
    ...slot,
    calendarID: slot.calendarID.toString(),
    appointmentTypeID: slot.appointmentTypeID.toString(),
  }));
export type IAcuityAppointmentSlotValidated = z.infer<
  typeof ZAcuityAppointmentSlotValidated
>;

export const ZValidSlots = z.array(ZAcuityAppointmentSlotValidated);

const ZAcuityAppointmentType = z.object({
  id: z.number(),
  active: z.boolean(),
  name: z.string(),
  duration: z.number(),
  calendarIDs: z.array(z.number()),
});
export type AcuityAppointmentType = z.infer<typeof ZAcuityAppointmentType>;

const ZAppointmentType = ZAcuityAppointmentType.omit({
  id: true,
}).extend({
  _id: z.string(),
});
export type IAppointmentType = z.infer<typeof ZAppointmentType>;

const ZAcuityAppointment = z.object({
  id: z.number(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  phone: z.string(),
  datetime: z.string(),
  datetimeCreated: z.string(),
  dateCreated: z.string(),
  duration: z.string(),
  calendar: z.string(),
  calendarID: z.number(),
  type: z.string(),
  appointmentTypeID: z.number(),
  canceled: z.boolean(),
  confirmationPage: z.string(),
  location: z.string(),
  forms: z.array(
    z.object({
      id: z.number(),
      values: z.array(
        z.object({ fieldID: z.number(), value: z.string(), name: z.string() }),
      ),
    }),
  ),
});
export type IAcuityAppointment = z.infer<typeof ZAcuityAppointment>;
