export type Colors = {
  blue: string;
  blueDark: string;
  blueLight: string;
  black: string;
  grey: string;
  greyTransparent: string;
  greyDark: string;
  greyLight: string;
  greyLightest: string;
  white: string;
  red: string;
  redLight: string;
  amber: string;
  amberLight: string;
  green: string;
  greenLight: string;
};

export const colors: Colors = {
  blue: '#246CCC',
  blueDark: '#28324D',
  blueLight: '#E3F0FD',
  black: '#0F1B38',
  grey: '#9F9F9F',
  greyTransparent: '#42495e66',
  greyDark: '#42495E',
  greyLight: '#CECECE',
  greyLightest: '#F6F6F6',
  white: '#FFFFFF',
  red: '#DA2D2D',
  redLight: '#F5DFDF',
  amber: '#FF7A00',
  amberLight: '#FFE8D9',
  green: '#1E8547',
  greenLight: '#E5F5E6',
};
