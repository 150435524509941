import { Theme } from '@mui/material';
import { DataGridComponents } from '@mui/x-data-grid/themeAugmentation';

/* colours not currently from ./palette.ts - need to clarify whether these are to be standardised */
const borderDefault = '1px solid #DEDEDE';

export const muiDataGridOverrides: DataGridComponents<
  Omit<Theme, 'components'>
>['MuiDataGrid'] = {
  styleOverrides: {
    root: {
      border: 0,
      fontSize: '16px',
    },
    columnHeader: {
      background: '#FAFAFA',
      borderBottom: borderDefault,
      headerAlign: 'left',
      padding: '20px 0 20px 16px',
    },
    columnHeaders: {
      border: 0,
    },
    columnHeaderTitle: {
      fontWeight: '500',
    },
    cell: {
      borderBottom: borderDefault,
      height: 'none',
      padding: '20px 0 16px 16px',
      fontSize: '16px',
      alignItems: 'start',
    },
    row: {
      ':last-child': {
        marginBottom: '64px',
      },
    },
    iconSeparator: {
      display: 'none',
    },
  },
};
