import { SmsFailed } from '@mui/icons-material';
import { Button } from '@mui/material';

import { Avo } from 'infrastructure/analytics';

export default function FeedbackButton() {
  return (
    <Button
      LinkComponent="a"
      href="https://dqmp1l111tv.typeform.com/to/EjrS9Yib"
      target="_blank"
      size="small"
      variant="outlined"
      startIcon={<SmsFailed />}
      onClick={() => Avo.feedbackFormOpened()}
    >
      Give us feedback
    </Button>
  );
}
