import { IPartnerPatientDetails } from '@suvera/api-types';
import { atom } from 'recoil';

export const defaultPatientDetails: IPartnerPatientDetails = {
  id: '',
  name: {
    first: '',
    last: '',
  },
  dateOfBirth: null,
  nhsNumber: '',
  dateOfLastSms: null,
  lastSmsContent: null,
  conditions: [],
  ndhTargetAreaState: undefined,
};

export const patientDetailsAtom = atom<IPartnerPatientDetails>({
  key: 'patientDetailsAtom',
  default: defaultPatientDetails,
});
