import { captureException } from '@sentry/react';
import axios, { AxiosError } from 'axios';
import { useAuthenticatedAxios } from 'infrastructure/suveraApi';
import { useQuery } from 'react-query';
import { z } from 'zod';
import { ZDashboardPartner, IDashboardPartner } from '@suvera/api-types';

const getPartnerResponseSchema = z.object({
  result: ZDashboardPartner,
});

const handleQueryError = (error: Error | AxiosError) => {
  if (axios.isAxiosError(error) && error.response) {
    return;
  }
  captureException(error);
  throw error;
};

type GetPartnerResponse = z.infer<typeof getPartnerResponseSchema>;

export const useGetAuthenticatedPartner = () => {
  const suveraAxiosInstance = useAuthenticatedAxios();
  return useQuery({
    queryKey: ['partnerMe'],
    queryFn: async (): Promise<IDashboardPartner | null> =>
      suveraAxiosInstance
        .get<GetPartnerResponse>('/partners/me')
        .then((response) => {
          return getPartnerResponseSchema.parse(response.data).result;
        })
        .catch((error) => {
          if (axios.isAxiosError(error) && error.response?.status === 403) {
            return null;
          }
          throw error;
        }),
    retry: false,
    onError: handleQueryError,
  });
};

export const useShouldShowPlanner = () => {
  const { data: partner, isLoading } = useGetAuthenticatedPartner();

  if (
    !partner ||
    !partner.featureFlags ||
    Object.keys(partner.featureFlags).length === 0
  )
    return {
      isLoading,
      shouldShowPlanner: false,
    };

  return {
    isLoading,
    shouldShowPlanner: partner.featureFlags['partner-planner'] ?? false,
  };
};

export const usePracticeName = () => {
  const { data } = useGetAuthenticatedPartner();

  if (!data) return '';
  return data.practice.name;
};
