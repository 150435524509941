import { RequirePartner } from 'App/PartnerAuth';
import LoadingState from 'components/LoadingState';
import {
  Auth0CallbackComponent,
  Logout,
  RequireAuth,
} from 'infrastructure/auth';
import { SentryTestComponent } from 'infrastructure/sentry';
import CreateReferralPage from 'pages/CreateReferralPage';
import FindPatientPage from 'pages/FindPatientPage';
import NotFoundPage from 'pages/NotFoundPage';
import PastReferralsPage from 'pages/PastReferralsPage';
import PlannerListPage from 'pages/PlannerListPage';
import PatientViewPage from 'pages/PatientViewPage';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useShouldShowPlanner } from './PartnerAuth/requests';
import { ROUTES } from './routes';

type Props = {
  plannerElement: React.ReactNode;
  fallbackElement: React.ReactNode;
};

const ConditionallyRenderForPlanner = ({
  plannerElement,
  fallbackElement,
}: Props) => {
  const { isLoading, shouldShowPlanner } = useShouldShowPlanner();

  if (isLoading) return <LoadingState />;

  if (shouldShowPlanner) return plannerElement;

  return fallbackElement;
};

const PartnerDashboardRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <RequireAuth>
            <ConditionallyRenderForPlanner
              plannerElement={<Navigate to={ROUTES.planner} />}
              fallbackElement={<Navigate to={ROUTES.find_a_patient} />}
            />
          </RequireAuth>
        }
      />
      <Route
        path={ROUTES.create_referral}
        element={
          <RequireAuth>
            <RequirePartner>
              <CreateReferralPage />
            </RequirePartner>
          </RequireAuth>
        }
      />
      <Route
        path={ROUTES.past_referrals}
        element={
          <RequireAuth>
            <RequirePartner>
              <PastReferralsPage />
            </RequirePartner>
          </RequireAuth>
        }
      />
      <Route
        path={ROUTES.find_a_patient}
        element={
          <RequireAuth>
            <RequirePartner>
              <ConditionallyRenderForPlanner
                plannerElement={<Navigate to={ROUTES.planner} />}
                fallbackElement={<FindPatientPage />}
              />
            </RequirePartner>
          </RequireAuth>
        }
      />
      <Route
        path={ROUTES.planner}
        element={
          <RequireAuth>
            <RequirePartner>
              <ConditionallyRenderForPlanner
                plannerElement={<PlannerListPage />}
                fallbackElement={<NotFoundPage />}
              />
            </RequirePartner>
          </RequireAuth>
        }
      />
      <Route path="/auth0" element={<Auth0CallbackComponent />} />
      <Route path={ROUTES.logout} element={<Logout />} />
      <Route path="/__error" element={<SentryTestComponent />} />
      <Route path={ROUTES.planner_patient} element={<PatientViewPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default PartnerDashboardRoutes;
