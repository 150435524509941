import { FC, ReactNode } from 'react';

import { ErrorBoundary } from '@sentry/react';
import { Colors, theme } from '@suvera/theme';

import PageTracker from 'infrastructure/analytics/PageTracker';
import { SuveraApiQueryProvider } from 'infrastructure/ReactQuery';
import { ErrorBoundaryFallbackPage } from 'components/Errors';

import { ThemeProvider } from '@mui/material/styles';
import { UserListener } from './PartnerAuth';
import PartnerDashboardRoutes from './PartnerDashboardRoutes';

interface AppProps {
  children?: ReactNode;
}

declare module '@mui/material/styles/createPalette' {
  export interface Palette {
    colors: Colors;
  }
  export interface PaletteOptions {
    colors: Colors;
  }
}

const App: FC<AppProps> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary fallback={<ErrorBoundaryFallbackPage />}>
        <SuveraApiQueryProvider>
          <>
            <UserListener />
            <PageTracker />
            {children || <PartnerDashboardRoutes />}{' '}
          </>
        </SuveraApiQueryProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
