import { GenericError } from 'components/Errors';
import LoadingState from 'components/LoadingState';
import { Avo } from 'infrastructure/analytics';
import { FC, lazy, ReactElement, Suspense, useEffect } from 'react';
import { useGetAuthenticatedPartner } from '../requests';

const UnknownPartnerScreen = lazy(() => import('./UnknownPartnerScreen'));

export const RequirePartner: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const partnerQuery = useGetAuthenticatedPartner();

  useEffect(() => {
    if (partnerQuery.isSuccess && partnerQuery.data === null) {
      Avo.unknownUserScreenSeen();
    }
  }, [partnerQuery.isSuccess, partnerQuery.data]);

  if (partnerQuery.isError) {
    return <GenericError />;
  }

  if (partnerQuery.isSuccess && partnerQuery.data === null) {
    return (
      <Suspense fallback={<LoadingState />}>
        <UnknownPartnerScreen />
      </Suspense>
    );
  }

  if (partnerQuery.isSuccess) {
    return children;
  }

  return <LoadingState />;
};
