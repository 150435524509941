import Box from '@mui/material/Box';
import type { FC, ReactNode } from 'react';

import Header from '../Header';

const Page: FC<{ children: ReactNode }> = ({ children }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'stretch',
      flexDirection: 'column',
      minHeight: '100vh',
    }}
  >
    <Box
      sx={{
        top: 0,
        left: 0,
        right: 0,
      }}
    >
      <Header />
    </Box>
    <Box
      sx={{
        minHeight: '100vh',
        boxSizing: 'border-box',
      }}
    >
      {children}
    </Box>
  </Box>
);

export default Page;
