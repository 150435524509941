import { ArrowBack } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { ROUTES } from 'App/routes';
import { addSpacesToNhsNumber } from 'utils/addSpacesToNhsNumber';

import { Avo } from 'infrastructure/analytics';
import { FindPatientContainer } from 'pages/FindPatientPage/components/FindPatientContainer';

export default function UnknownPatientView() {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
    Avo.findAPatientBackButtonClicked();
  };

  const nhsNumber = useParams().nhsNumber as string;

  const handleCreateReferralClick = () => {
    navigate(`${ROUTES.create_referral}?nhsNumber=${nhsNumber}`);
    Avo.findAPatientCreateReferralButtonClicked();
  };

  return (
    <FindPatientContainer>
      <Stack spacing="16px">
        <Stack spacing="8px">
          <Button
            onClick={handleBackClick}
            startIcon={<ArrowBack />}
            variant="text"
            sx={{ width: 'fit-content' }}
          >
            Back
          </Button>

          <Typography variant="h2" component="h1">
            We couldn&apos;t find this NHS number
          </Typography>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography fontWeight={600}>
            {addSpacesToNhsNumber(nhsNumber)}
          </Typography>

          <Button
            variant="outlined"
            sx={{ width: 'fit-content' }}
            size="small"
            onClick={handleCreateReferralClick}
          >
            Create a referral
          </Button>
        </Stack>
      </Stack>
    </FindPatientContainer>
  );
}
