export const linkToPatientView = (nhsNumber: string): string =>
  `/planner/patient/${nhsNumber}`;

export const ROUTES = {
  create_referral: '/create-a-new-referral',
  past_referrals: '/referrals',
  logout: '/logout',
  find_a_patient: '/find-a-patient',
  homepage: '/',
  planner: '/planner',
  planner_patient: linkToPatientView(':nhsNumber'),
};
