import {
  BirthMonthFilter,
  GetPatientListRequest,
  TargetAreaManagementFilter,
  TargetAreaStateFilter,
  ZPageFilter,
  ZTargetAreaManagementFilter,
  ZTargetAreaStateFilter,
  ZMonthOfBirthFilter,
} from '@suvera/api-types';
import { useSearchParams } from 'react-router-dom';

export const usePlannerParams = (): GetPatientListRequest => {
  const [searchParams] = useSearchParams();

  const values: GetPatientListRequest = {
    targetAreaManagement: 'practice' as TargetAreaManagementFilter,
    targetAreaStates: ['incomplete'] as TargetAreaStateFilter,
    monthsOfBirth: [] as BirthMonthFilter,
    targetArea: 'nonDiabeticHyperglycaemia',
    page: 0,
  };

  const targetAreaManagementParsed = ZTargetAreaManagementFilter.safeParse(
    searchParams.get('targetAreaManagement'),
  );

  if (targetAreaManagementParsed.success) {
    values.targetAreaManagement = targetAreaManagementParsed.data;
  }

  const pageParsed = ZPageFilter.safeParse(
    Number.parseInt(searchParams.get('page') ?? '', 10),
  );

  if (pageParsed.success) {
    values.page = pageParsed.data;
  }

  const monthsOfBirthParsed = ZMonthOfBirthFilter.safeParse(
    searchParams
      .getAll('monthOfBirth')
      .map((month) => Number.parseInt(month, 10)),
  );

  if (monthsOfBirthParsed.success) {
    values.monthsOfBirth = monthsOfBirthParsed.data;
  }

  const targetAreaStateParsed = ZTargetAreaStateFilter.safeParse(
    searchParams.getAll('targetAreaState'),
  );

  if (targetAreaStateParsed.success) {
    values.targetAreaStates = targetAreaStateParsed.data;
  }
  return values;
};
