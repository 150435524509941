import LoadingState from 'components/LoadingState';
import Page from 'components/Page';
import { lazy, Suspense } from 'react';

const FindPatientPageLazy = lazy(
  () => import(/* webpackChunkName: "FindPatientPage" */ './FindPatientPage'),
);

const FindPatientPage = () => {
  return (
    <Suspense fallback={<LoadingState />}>
      <Page>
        <FindPatientPageLazy />
      </Page>
    </Suspense>
  );
};

export default FindPatientPage;
