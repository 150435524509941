import {
  TypographyOptions,
  TypographyStyleOptions,
} from '@mui/material/styles/createTypography';

import '@fontsource/inter/400.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';

const fonts = ['Inter', 'Arial', 'sans-serif'];

const base = 16;
const SIZE_0 = 14;
const SIZE_1 = base;
const SIZE_2 = 18;
const SIZE_3 = 24;
const SIZE_4 = 28;

const defaultFontStyle = {
  fontWeight: '400',
};

const styleOptions = (
  fontSizeMobile: number,
  fontSizeDesktop: number,
  options: TypographyStyleOptions = {},
) => ({
  ...defaultFontStyle,
  fontSize: `${fontSizeMobile}px`,
  lineHeight: `${fontSizeMobile * 1.5}px`,
  '@media (min-width:900px)': {
    fontSize: `${fontSizeDesktop}px`,
    lineHeight: `${fontSizeDesktop * 1.5}px`,
  },
  ...options,
});

const regularFont = (fontSizeMobile: number, fontSizeDesktop: number) => ({
  ...styleOptions(fontSizeMobile, fontSizeDesktop),
});

const boldFont = (fontSizeMobile: number, fontSizeDesktop: number) => ({
  ...styleOptions(fontSizeMobile, fontSizeDesktop, {
    fontWeight: 600,
  }),
});

export const typography: TypographyOptions = {
  fontFamily: fonts.join(', '),
  h1: boldFont(SIZE_4, SIZE_4),
  h2: boldFont(SIZE_3, SIZE_3),
  h3: boldFont(SIZE_2, SIZE_2),
  body1: regularFont(SIZE_2, SIZE_1),
  body2: regularFont(SIZE_1, SIZE_0),
  button: { ...boldFont(SIZE_1, SIZE_1), textTransform: 'none' },
};
