import { ArrowBack, Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { TargetAreaStatusReason } from '@suvera/core-types/types/targets';
import { theme } from '@suvera/theme';
import { ROUTES } from 'App/routes';
import axios, { AxiosError } from 'axios';
import LoadingState from 'components/LoadingState';
import Page from 'components/Page';
import { format } from 'date-fns/fp';
import { Avo } from 'infrastructure/analytics';
import { useAuthenticatedAxios } from 'infrastructure/suveraApi';
import { truncate } from 'lodash';
import { useGetPatientByNhsNumber } from 'pages/CreateReferralPage/requests/useGetPatientByNhsNumber';
import UpcomingAppointments from 'pages/FindPatientPage/components/UpcomingAppointments';
import { PropsWithChildren, Suspense, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { sanitiseNhsNumber } from 'schemas/nhsNumbers';
import { addSpacesToNhsNumber } from 'utils/addSpacesToNhsNumber';

import {
  FindPatientBox,
  FindPatientOuterContainer,
} from 'pages/FindPatientPage/components/FindPatientContainer';

import DischargePatientForm from './components/DischargePatientForm';
import PlannerSnackbar from './components/PlannerSnackbar';

import UnknownPatientView from './components/UnknownPatientView';

const formatDOB = format('dd/MM/yyyy');
const formatDate = format('d MMMM yyyy');

const OutlinedGreyBox = ({ children }: PropsWithChildren<object>) => {
  return (
    <Box
      sx={{
        padding: '8px',
        backgroundColor: `${theme.palette.colors.greyLightest}`,
        border: `solid 1px ${theme.palette.colors.grey}`,
        borderRadius: '4px',
      }}
    >
      {children}
    </Box>
  );
};

const SMSDialog = ({
  open,
  handleClose,
  dateOfLastSms,
  lastSmsContent,
}: {
  open: boolean;
  handleClose: () => void;
  dateOfLastSms: Date | null;
  lastSmsContent: string | null;
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      data-testid="last_sms_modal"
      maxWidth="md"
      fullWidth
    >
      <Stack direction="row" justifyContent="space-between">
        <DialogTitle component="h1" variant="h2">
          Last SMS sent from Suvera
        </DialogTitle>

        <Box paddingX="24px" paddingY="16px">
          <IconButton
            onClick={handleClose}
            size="medium"
            aria-label="Close modal"
            sx={{ color: theme.palette.colors.black }}
          >
            <Close fontSize="inherit" />
          </IconButton>
        </Box>
      </Stack>
      <DialogContent sx={{ paddingTop: '0px' }}>
        {dateOfLastSms && lastSmsContent ? (
          <>
            <Typography>SMS sent: {formatDate(dateOfLastSms)}</Typography>
            <Box sx={{ marginTop: '16px', paddingX: '20%' }}>
              <Box
                sx={{
                  backgroundColor: theme.palette.colors.greyLight,
                  borderRadius: '16px 16px 16px 0px',
                  padding: '16px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                <Typography>{lastSmsContent}</Typography>
              </Box>
            </Box>
          </>
        ) : (
          <OutlinedGreyBox>
            <Typography>No last SMS</Typography>
          </OutlinedGreyBox>
        )}
      </DialogContent>
    </Dialog>
  );
};

const PatientViewPage = () => {
  const navigate = useNavigate();
  const [isDischargeModalOpen, setIsDischargeModalOpen] = useState(false);
  const [isAlertShown, setIsAlertShown] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');

  const nhsNumber = useParams().nhsNumber || '';

  const {
    refetch,
    data: patient,
    isLoading,
  } = useGetPatientByNhsNumber(sanitiseNhsNumber(nhsNumber));

  // useGetPatientByNhsNumber is off on mount by default so force the fetch here
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    refetch();
  }, [refetch]);

  const handleBackClick = () => {
    Avo.patientViewBackButtonClicked();
    navigate(-1);
  };

  const useDischargePatient = () => {
    const suveraAxiosInstance = useAuthenticatedAxios();

    return useMutation({
      mutationFn: (dischargeReason: TargetAreaStatusReason) => {
        return suveraAxiosInstance.post(
          `/patients/${patient?.id}/discharge-request`,
          {
            type: 'json',
            dischargeReason,
          },
        );
      },
      onSuccess: () => {
        setAlertMessage('Discharge request sent to Suvera.');
        setIsError(false);
        setIsAlertShown(true);
        Avo.patientViewDischargeSuccessMessageViewed();
      },
      onError: (error: Error | AxiosError) => {
        setAlertMessage('Something went wrong. Please try again.');
        setIsError(true);
        setIsAlertShown(true);
        Avo.patientViewDischargeErrorMessageViewed();
        if (axios.isAxiosError(error) && error.response) {
          return;
        }
        captureException(error);
      },
    });
  };

  const dischargePatient = useDischargePatient();

  const handleDischargeRequest = (dischargeReason: TargetAreaStatusReason) => {
    dischargePatient.mutate(dischargeReason);
    setIsDischargeModalOpen(false);
  };

  const [lastSmsModal, setLastSmsModal] = useState<boolean>(false);

  const handleModalOpen = () => {
    Avo.patientViewLastSmsModalOpened();
    setLastSmsModal(true);
  };

  const handleModalClose = () => {
    Avo.patientViewLastSmsModalClosed();
    setLastSmsModal(false);
  };

  const handleCreateReferralClick = () => {
    Avo.patientViewReferralButtonClicked();
    navigate(
      `${ROUTES.create_referral}?nhsNumber=${sanitiseNhsNumber(nhsNumber)}`,
    );
  };

  if (isLoading)
    return (
      <Page>
        <LoadingState />
      </Page>
    );

  if (!patient) {
    return (
      <Page>
        <UnknownPatientView />
      </Page>
    );
  }

  return (
    <Suspense fallback={<LoadingState />}>
      <Page>
        <FindPatientOuterContainer>
          <Box
            marginBottom="16px"
            paddingX="80px"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Button
              onClick={handleBackClick}
              startIcon={<ArrowBack />}
              variant="text"
              sx={{ width: 'fit-content' }}
            >
              Back
            </Button>

            <Stack spacing="8px" direction="row">
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  setIsDischargeModalOpen(true);
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                  Avo.patientViewDischargeButtonClicked();
                }}
              >
                Discharge from Suvera
              </Button>

              <Button
                variant="contained"
                sx={{ width: 'fit-content' }}
                size="small"
                onClick={handleCreateReferralClick}
              >
                Create a referral
              </Button>
            </Stack>
          </Box>

          <DischargePatientForm
            open={isDischargeModalOpen}
            handleClose={() => setIsDischargeModalOpen(false)}
            handleSubmit={(dischargeReason: TargetAreaStatusReason) =>
              handleDischargeRequest(dischargeReason)
            }
            patientName={`${patient.name.first} ${patient.name.last}`}
          />

          <PlannerSnackbar
            isOpen={isAlertShown}
            onClose={() => setIsAlertShown(false)}
            message={alertMessage}
            isError={isError}
          />

          <FindPatientBox maxWidth="md">
            <Stack spacing="16px">
              <Typography variant="h3">
                {patient.name.first} {patient.name.last}
              </Typography>

              <Stack spacing="12px" direction="row">
                <Stack spacing="8px" data-testid="nhs_number" flex={1}>
                  <Typography variant="body2">NHS number</Typography>

                  <Chip
                    size="medium"
                    variant="outlined"
                    sx={{ width: '100%' }}
                    label={addSpacesToNhsNumber(patient.nhsNumber)}
                  />
                </Stack>

                <Stack spacing="8px" data-testid="date_of_birth" flex={1}>
                  <Typography variant="body2">Date of birth</Typography>

                  <Chip
                    size="medium"
                    variant="outlined"
                    sx={{ width: '100%' }}
                    label={
                      patient.dateOfBirth ? formatDOB(patient.dateOfBirth) : '-'
                    }
                  />
                </Stack>

                <Stack spacing="8px" data-testid="target_status" flex={1}>
                  <Typography variant="body2">Target status</Typography>

                  <Chip
                    size="medium"
                    variant="outlined"
                    sx={{ width: '100%' }}
                    label={patient.ndhTargetAreaState || '-'}
                  />
                </Stack>
              </Stack>

              <Box display="flex" flexDirection="row">
                <Stack spacing="8px" flex={1} data-testid="last_sms">
                  <Typography variant="body2">
                    Last SMS sent from Suvera
                  </Typography>

                  <OutlinedGreyBox>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent={'center'}
                    >
                      {patient.dateOfLastSms ? (
                        <>
                          <Typography flexGrow={1} variant="body2">
                            <>
                              <Typography
                                sx={{ fontWeight: 'bold' }}
                                component="span"
                                variant="body2"
                              >{`${formatDate(patient.dateOfLastSms)}: `}</Typography>

                              <Typography component="span" variant="body2">
                                {truncate(`${patient.lastSmsContent}`, {
                                  length: 150,
                                })}
                              </Typography>
                            </>
                          </Typography>

                          <Button onClick={handleModalOpen}>View</Button>
                        </>
                      ) : (
                        <Typography variant="body2">No last SMS</Typography>
                      )}
                    </Box>
                  </OutlinedGreyBox>
                </Stack>
              </Box>

              <Stack spacing="8px" data-testid="upcoming_appointments">
                <Typography variant="body2">Upcoming appointments</Typography>

                <UpcomingAppointments overridePatientId={patient.id} />
              </Stack>
            </Stack>
          </FindPatientBox>
        </FindPatientOuterContainer>

        <SMSDialog
          open={lastSmsModal}
          handleClose={handleModalClose}
          dateOfLastSms={patient.dateOfLastSms}
          lastSmsContent={patient.lastSmsContent}
        />
      </Page>
    </Suspense>
  );
};

export default PatientViewPage;
