import { Close } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import FeedbackButton from 'components/Header/components/FeedbackButton';

const patientSupportNumber = '0800 808 5288';
const patientSupportNumberHref = 'tel:+448008085288';

const patientPortalText = 'patient.suvera.org.uk';
const patientPortalHref = 'https://patient.suvera.org.uk';

const patientSupportEmail = 'support@suvera.co.uk';
const patientSupportEmailHref = `mailto:${patientSupportEmail}`;

const practiceSupportNumber = '020 3882 5131';
const practiceSupportNumberHref = 'tel:+442038825131';

const practiceSupportEmail = 'partners@suvera.org.uk';
const practiceSupportEmailHref = `mailto:${practiceSupportEmail}`;

type Props = {
  open: boolean;
  handleClose: () => void;
};

const HelpModal = ({ open, handleClose }: Props) => (
  <Dialog
    open={open}
    onClose={handleClose}
    data-testid="help_and_contact_modal"
    maxWidth="xs"
    fullWidth
  >
    <Stack direction="row" justifyContent="space-between">
      <DialogTitle component="h1" variant="h2">
        Help
      </DialogTitle>

      <Box paddingX="24px" paddingY="16px">
        <IconButton
          onClick={handleClose}
          size="medium"
          aria-label="Close modal"
          sx={{ color: 'black' }}
        >
          <Close fontSize="inherit" />
        </IconButton>
      </Box>
    </Stack>

    <DialogContent sx={{ paddingTop: '0px' }}>
      <Stack spacing="24px">
        <Stack spacing="4px" data-testid="patient_support">
          <Typography variant="body1" fontWeight={600}>
            Patient Support
          </Typography>

          <Stack spacing="2px">
            <Typography variant="body1">
              Patient phone number:{' '}
              <Link href={patientSupportNumberHref}>
                {patientSupportNumber}
              </Link>
            </Typography>

            <Typography variant="body1">
              Patient support email:{' '}
              <Link href={patientSupportEmailHref}>{patientSupportEmail}</Link>
            </Typography>

            <Typography variant="body1">
              Online patient portal:{' '}
              <Link href={patientPortalHref}>{patientPortalText}</Link>
            </Typography>
          </Stack>
        </Stack>

        <Stack spacing="4px" data-testid="practice_support">
          <Typography variant="body1" fontWeight={600}>
            Practice Support
          </Typography>

          <Stack spacing="2px">
            <Typography variant="body1">
              Support phone number:{' '}
              <Link href={practiceSupportNumberHref}>
                {practiceSupportNumber}
              </Link>
            </Typography>

            <Typography variant="body1">
              Support email:{' '}
              <Link href={practiceSupportEmailHref}>
                {practiceSupportEmail}
              </Link>
            </Typography>
          </Stack>
        </Stack>

        <Box>
          <FeedbackButton />
        </Box>
      </Stack>
    </DialogContent>
  </Dialog>
);

export default HelpModal;
