export const EClinicalReadingTypeList = [
  'blood_pressure_systole',
  'blood_pressure_diastole',
  'eGFR',
  'HbA1c',
  'HDL',
  'Non-HDL',
  'LDL',
  'total_cholesterol',
  'cvd_risk_score',
  'suitable_for_hypcf',
  'diabetes_review',
  'hypertension_review',
  'asthma_review',
  'acr_test',
  'creatinine_result',
  'suvera_exclusion',
  'suvera_exclusion_diabetes',
  'suvera_exclusion_asthma',
  'priority_group_cholesterol',
  'priority_group_diabetes',
  'priority_group_hypertension',
  'uclp_risk',
  'pulse_rate',
  'non_smoker',
  'ex_smoker',
  'ex_smoker_less_than_1_year',
  'ex_smoker_greater_than_1_year',
  'smoker',
  'occasional_smoker_less_than_1_per_day',
  'light_smoker_1_to_9_per_day',
  'moderate_smoker_10_to_19_per_day',
  'heavy_smoker_over_20_per_day',
  'weight',
  'height',
  'bmi',
  'alcohol_units_consumed_per_week_observable_entity',
  'dietary_sodium_intake_finding',
  'level_of_physical_activity_observable_entity',
  'identifying_barriers_to_goal_achievement_procedure',
  'additional_note',
  'curious_character_finding',
  'anxious_character_finding',
  'partner_bespoke_list',
] as const;

export type EClinicalReadingType = (typeof EClinicalReadingTypeList)[number];
