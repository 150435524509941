import { Colors, colors } from './colors';

declare module '@mui/material/styles/createPalette' {
  export interface Palette {
    colors: Colors;
  }
  export interface PaletteOptions {
    colors: Colors;
  }
}

export const palette = {
  primary: {
    main: colors.blue,
    dark: colors.blueDark,
  },
  text: {
    primary: colors.black,
  },
  error: { main: colors.red },
  warning: { main: colors.amber },
  success: {
    main: colors.green,
  },
  colors,
};
