import { ZPatient, ZTargetAreaKeys } from '@suvera/core-types';
import { z } from 'zod';

/* Filters */

export const ZTargetAreaManagementFilter = z.enum([
  'suvera',
  'practice',
  'to-be-onboarded',
]);
export type TargetAreaManagementFilter = z.infer<
  typeof ZTargetAreaManagementFilter
>;

export const ZMonthOfBirthFilter = z.array(z.number().min(1).max(12));
export type BirthMonthFilter = z.infer<typeof ZMonthOfBirthFilter>;

export const ZTargetAreaStateFilter = z.array(
  z.enum(['complete', 'incomplete']),
);
export type TargetAreaStateFilter = z.infer<typeof ZTargetAreaStateFilter>;

export const ZPageFilter = z.number().min(0).default(0);
export type PageFilter = z.infer<typeof ZPageFilter>;

/* API responses */

const ZPlannerPatientPickedFields = ZPatient.pick({
  _id: true,
  nhs_number: true,
  name: true,
  date_of_birth: true,
});
export const ZPlannerPatient = ZPlannerPatientPickedFields.extend({
  displayedTargetAreas: z.string().array(),
});
export type IPlannerPatient = z.infer<typeof ZPlannerPatient>;

export const ZExportPatientListRequest = z
  .object({
    monthsOfBirth: ZMonthOfBirthFilter,
    targetAreaManagement: ZTargetAreaManagementFilter,
    targetAreaStates: ZTargetAreaStateFilter,
    targetArea: ZTargetAreaKeys,
  })
  .strict();

export type IExportPatientListRequest = z.infer<
  typeof ZExportPatientListRequest
>;

export const ZGetPatientListRequest = ZExportPatientListRequest.extend({
  page: z.number().min(0).default(0),
}).strict();

export type GetPatientListRequest = z.infer<typeof ZGetPatientListRequest>;

export const ZGetPatientListResponseMeta = z.object({
  total: z.number(),
  offset: z.number(),
  limit: z.number(),
  total_pages: z.number(),
});

export type GetPatientListResponseMeta = z.infer<
  typeof ZGetPatientListResponseMeta
>;

export const ZGetPatientListResponse = z.object({
  meta: ZGetPatientListResponseMeta,
  result: z.array(ZPlannerPatient),
});

export type GetPatientListResponse = z.infer<typeof ZGetPatientListResponse>;

export const ZExportListResponse = z.object({
  statusCode: z.number(),
  headers: z
    .object({
      'Content-Type': z.literal('text/csv'),
      'Content-Disposition': z.string(),
      'Access-Control-Expose-Headers': z.literal('Content-Disposition'),
    })
    .optional(),
  body: z.string(),
});

export type ExportListResponse = z.infer<typeof ZExportListResponse>;

export type CsvExportListData = {
  'Patient name': string;
  'NHS number': string;
  'Date of birth': string;
  'UCLP risk': string;
};
