import { captureException } from '@sentry/react';
import {
  GetPatientListRequest,
  GetPatientListResponse,
  ZGetPatientListResponse,
} from '@suvera/api-types';
import axios, { AxiosError } from 'axios';
import { useAuthenticatedAxios } from 'infrastructure/suveraApi';
import { useQuery } from 'react-query';

export const useGetPatientList = (request: GetPatientListRequest) => {
  const suveraAxiosInstance = useAuthenticatedAxios();

  return useQuery({
    queryKey: ['patientList', request],
    queryFn: async (): Promise<GetPatientListResponse> => {
      return suveraAxiosInstance
        .post<GetPatientListResponse>('/planner/patients', request)
        .then((response) => ZGetPatientListResponse.parse(response.data));
    },
    retry: false,
    onError: (error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        return;
      }
      captureException(error);
    },
  });
};
