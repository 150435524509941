import { HelpOutline } from '@mui/icons-material';
import { TabContext, TabList } from '@mui/lab';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { AppBar, Box, Button, Skeleton, Stack, Tab } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { useShouldShowPlanner } from 'App/PartnerAuth/requests';
import { ROUTES } from 'App/routes';
import { Avo } from 'infrastructure/analytics';
import LastSyncedBanner from 'pages/PlannerListPage/components/LastSyncedBanner';
import { ReactComponent as SuveraLogo } from '../svgs/suvera-logo.svg';
import HelpModal from './components/HelpModal';
import { locationPathnameAtom } from './locationPathnameAtom';

const eventTabNameFromRoute = (route: string) => {
  if (route === ROUTES.past_referrals) return 'past_referrals';
  if (route === ROUTES.create_referral) return 'create_referral';
  if (route === ROUTES.find_a_patient) return 'find_a_patient';
  if (route.includes(ROUTES.planner)) return 'planner';
  return null;
};

export default function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useRecoilState(locationPathnameAtom);
  const { shouldShowPlanner, isLoading } = useShouldShowPlanner();

  useEffect(() => {
    // Planner tabs should also show "Planner" tab as selected
    if (location.pathname.includes(ROUTES.planner)) {
      setValue(ROUTES.planner);
    } else {
      setValue(location.pathname);
    }
  }, [location.pathname, setValue]);

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    const tab = eventTabNameFromRoute(newValue);
    if (tab) {
      Avo.navigationButtonClicked({ tab });
    }
    setValue(newValue);
    navigate(newValue);
  };

  const theme = useTheme();

  const headerTabStyles = {
    fontWeight: 400,
    color: 'text.primary',
    '&.Mui-selected': {
      color: 'text.primary',
      fontWeight: 600,
    },
    paddingY: '8px',
    paddingX: '0px',
    marginX: '16px',
  };

  const isPlannerRoute = location.pathname.startsWith(ROUTES.planner);

  return (
    <>
      {isPlannerRoute && <LastSyncedBanner />}
      <Box
        component="header"
        sx={{
          backgroundColor: 'white',
          borderBottom: `0.5px solid ${theme.palette.colors.grey}`,
        }}
      >
        <AppBar
          component="nav"
          position="static"
          sx={{
            paddingX: { xs: '32px', lg: '80px' },
            boxShadow: 'none',
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: 'white',
            justifyContent: 'space-between',
          }}
        >
          <Box display="flex" flex={2} component={Link} to="/">
            <SuveraLogo title="Suvera logo" />
          </Box>

          <Box display="flex" flex={3} justifyContent="center">
            {isLoading ? (
              <Stack direction="row" paddingY="0.5rem" spacing="2rem">
                <Skeleton variant="rectangular" width="6rem" height="2rem" />
                <Skeleton variant="rectangular" width="6rem" height="2rem" />
                <Skeleton variant="rectangular" width="6rem" height="2rem" />
              </Stack>
            ) : (
              <TabContext value={value}>
                {
                  <TabList
                    onChange={handleChange}
                    aria-label="Navigation tabs"
                    TabIndicatorProps={{
                      sx: { backgroundColor: 'colors.blue', height: '4px' },
                    }}
                  >
                    {shouldShowPlanner ? (
                      <Tab
                        label="Planner"
                        sx={headerTabStyles}
                        value={ROUTES.planner}
                      />
                    ) : (
                      <Tab
                        label="Find a patient"
                        sx={headerTabStyles}
                        value={ROUTES.find_a_patient}
                      />
                    )}

                    <Tab
                      label="Create a referral"
                      sx={headerTabStyles}
                      value={ROUTES.create_referral}
                    />

                    <Tab
                      label="Past referrals"
                      sx={headerTabStyles}
                      value={ROUTES.past_referrals}
                    />
                  </TabList>
                }
              </TabContext>
            )}
          </Box>

          <Stack direction="row" flex={2} justifyContent="end" spacing="24px">
            <Button
              size="small"
              variant="text"
              onClick={() => {
                Avo.helpModalOpened();
                setIsOpen(true);
              }}
              startIcon={<HelpOutline />}
            >
              Help & contact
            </Button>

            <Button
              size="small"
              variant="outlined"
              onClick={() => navigate(ROUTES.logout)}
            >
              Sign out
            </Button>
          </Stack>
        </AppBar>
      </Box>
      <HelpModal
        open={isOpen}
        handleClose={() => {
          Avo.helpModalClosed();
          setIsOpen(false);
        }}
      />
    </>
  );
}
